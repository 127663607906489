<template>
    <Navbar />
    <div v-if="isTablet" class="side-overlay" :class="{ 'open': show }" @click="openNav"></div>
    <header class="header">
      <div class="container header__wrapper">
        <div v-if="!isTablet" class="header__top">
          <ul v-if="navigation" class="header__menu">
            <li v-for="(item, index) in navigation" :key="index" class="header__menu-item">
              <a :href="item.url" class="header__menu-link">{{ item.name }}</a>
            </li>
            <li class="header__menu-item">
              <span tabindex="0" class="btn-reset tooltip-icon" title="<div class='tooltip-inner__title'>Что такое скидка</div>Интернет-магазин «Техно Еж» — это площадка, где вы сможете найти самые актуальные предложения из мира техники.">
                  <svg width="12" height="12" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#tooltip"></use></svg>
              </span>
              <a href="" class="header__menu-link header__menu-link--red">
                SALE
              </a>
            </li>
          </ul>
          <LocaleDropdown />
        </div>
        <div class="header__bottom">
          <a href="" class="header__logo-link">
            <img src="@/assets/img/logo.svg" class="header__logo">
          </a>
          <a href="" class="header__catalog-btn header__indent">
            <svg width="24" height="21" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#burger"></use></svg>
            Каталог
          </a>
          <Search/>
          <div v-if="!isTablet" class="header-feedback header__indent">
            <div class="header-phone">
              <Phones/>
            </div>
            <button class="header-feedback__btn btn-reset link">{{ t('request_call') }}</button>
          </div>
          <!--
          <button class="btn-reset font-weight-700 link link--small">Договор</button>
          -->
          <div class="header-location header__indent" v-if="!isTablet">
            <svg class="icon-svg" width="14" height="14"><use xlink:href="@/assets/img/sprite.svg#cursor"></use></svg>
            <Branch/>
          </div>
          <button class="btn-open-search btn-reset" @click="showSearch = !showSearch"><svg class="icon-svg" width="20" height="20"><use xlink:href="@/assets/img/sprite.svg#search"></use></svg></button>
          <div class="header__user">
            <router-link :to="{ name: 'login' }" class="header__user-btn" v-if="!isTablet">
              <svg class="header__user-icon icon-svg"><use xlink:href="@/assets/img/sprite.svg#enter"></use></svg>
            </router-link>
            <a href="" class="header__user-btn" v-if="!isTablet">
              <div class="header__user-badge"><span>10</span></div>
              <svg class="header__user-icon icon-svg"><use xlink:href="@/assets/img/sprite.svg#hearth"></use></svg>
            </a>
            <a href="" class="header__user-btn header__user-btn--flex">
              <div class="header__user-badge"><span>4</span></div>
              <svg class="header__user-icon icon-svg"><use xlink:href="@/assets/img/sprite.svg#shop-cart"></use></svg>
            </a>
          </div>
          <button v-if="isTablet" class="menu-burger" @click="openNav">
            <span class="menu-burger__wrapper">
                <span class="menu-burger__line"></span>
                <span class="menu-burger__line"></span>
                <span class="menu-burger__line"></span>
            </span>
          </button>
        </div>
      </div>
    </header>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Navbar from './navbar'
import Branch from './branch'
import Phones from './phones'
import LocaleDropdown from './localeDropdown'
import Search from './search'
export default {
  components: {
    Search,
    Phones,
    Branch,
    LocaleDropdown,
    Navbar
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const width = ref(window.innerWidth)
    const showSearch = ref(false)
    onMounted(() => {
      window.onresize = () => {
        width.value = window.innerWidth
      }
      window.addEventListener('resize', isTabletCheck, false)
      window.addEventListener('load', isTabletCheck, false)

      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 43) {
          document.querySelector('.header').classList.add('header--sticky')
        } else {
          document.querySelector('.header').classList.remove('header--sticky')
        }
      })
      window.addEventListener('load', () => {
        if (window.pageYOffset > 43) {
          document.querySelector('.header').classList.add('header--sticky')
        } else {
          document.querySelector('.header').classList.remove('header--sticky')
        }
      })
      if (store.getters['menu/menu'].length === 0) {
        store.commit('menu/SET_MENU', [
          { name: 'Оплата и доставка', url: '' },
          { name: 'Гарантии', url: '' },
          { name: 'FAQ', url: '' },
          { name: 'Бренды', url: '' },
          { name: 'О нас', url: '' },
          { name: 'Новости', url: '' },
          { name: 'Контакты', url: '' },
          { name: 'SALE', url: '' },
          { name: 'Price', url: '' }
        ])
      }
    })
    const isTablet = computed(() => {
      if (width.value <= 992) {
        return true
      }
      return false
    })
    const navigation = computed(() => {
      return store.getters['menu/menu']
    })
    const show = computed(() => {
      return store.getters['menu/showMenu']
    })
    // methods
    function openNav () {
      const s = !store.getters['menu/showMenu']
      store.commit('menu/SHOW_MENU', s)
    }
    function isTabletCheck () {
      if (width.value <= 992) {
        return true
      }
      return false
    }
    return {
      showSearch,
      isTablet,
      navigation,
      show,
      t,
      openNav
    }
  }
}
</script>

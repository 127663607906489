import { createI18n } from 'vue-i18n'
import axios from 'axios'
import Cookies from 'js-cookie'

const i18n = createI18n({
  locale: 'ua',
  messages: {}
})
const loadedLanguages = []

export function setI18nLanguage (locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  document.querySelector('html').setAttribute('lang', locale)
}

/**
 * Get locale from API endpoint
 */
/*
function getLocaleApi () {
  axios.get(process.env.VUE_APP_API_URL + '/locale').then(resp => {
    if (resp.data) {
      store.dispatch('lang/setLocale', { locale: resp.data })
    }
  })
}
*/
export async function loadMessages (locale) {
  if (typeof locale !== 'undefined') {
    return axios.get(process.env.VUE_APP_API_URL + '/string-translations?locale=' + locale).then(resp => {
      const messages = resp.data.data
      console.log(messages)
      console.log('Locale: ', locale)
      loadedLanguages.push(locale)
      i18n.global.setLocaleMessage(locale, messages)
      setI18nLanguage(locale)
    })
  }
}

(async function () {
  // await getLocaleApi()
  if (!Cookies.get('locale')) {
    Cookies.set('locale', 'ua')
  }
  const locale = Cookies.get('locale')
  await loadMessages(locale)
}())

export default i18n

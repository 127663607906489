<template>
  <nav v-if="items" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="(item, index) in items" :key="index" class="breadcrumb-item" :class="{ 'active': item.active }">
        <router-link v-if="item.href" :to="item.href">{{ item.name }}</router-link>
        <span v-else>{{ item.name }}</span>
      </li>
    </ol>
</nav>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    items: Array
  },
  setup () {
    return {}
  }
}
</script>

import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from '~/components/App'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import Child from '~/components/Child'
import Breadcrumb from '~/components/global/Breadcrumb'
import './plugins'

// Init app
const app = createApp(App);
// Load components
[
  Child,
  Breadcrumb
].forEach(Component => {
  app.component(Component.name, Component)
})
app.use(store)
  .use(router)
  .use(i18n)
  .use(createMetaManager())
  .mount('#app')

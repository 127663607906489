import * as types from '../mutation-types'
import axios from 'axios'
// state
export const state = {
  pages: []
}
// getters
export const getters = {
  pages: state => state.pages
}
// mutations
export const mutations = {
  [types.SET_PAGES] (state, pages) {
    state.pages = pages
  }
}
// actions
export const actions = {
  async fetchPages ({ commit }) {
    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/pages')
    if (data.data) {
      const response = data.data
      commit(types.SET_PAGES, response)
    }
  }
}

import * as types from '../mutation-types'
import axios from 'axios'
import Cookies from 'js-cookie'
// state
export const state = {
  branches: [],
  branchPhones: [],
  branchId: 1
}
// getters
export const getters = {
  branches: state => state.branches,
  branchPhones: state => state.branchPhones,
  branchId: state => state.branchId,
  currentBranch: state => {
    const branch = state.branches.find(b => b.id === state.branchId)
    if (typeof branch !== 'undefined') {
      return branch
    }
    return []
  }
}
// mutations
export const mutations = {
  [types.SET_BRANCHES] (state, data) {
    state.branches = data
  },
  [types.SET_BRANCH_PHONES] (state, phones) {
    state.branchPhones = phones
  },
  [types.SET_BRANCH_ID] (state, id) {
    state.branchId = id
  }
}
// actions
export const actions = {
  async getBranches ({ commit }) {
    const locale = Cookies.get('locale')
    await axios.get(process.env.VUE_APP_API_URL + '/branches?locale=' + locale).then(resp => {
      if (resp.data) {
        const data = resp.data.data
        commit(types.SET_BRANCHES, data)
        if (data && data[0]) {
          commit(types.SET_BRANCH_ID, data[0].id)
          commit(types.SET_BRANCH_PHONES, data[0].phones)
        }
      }
    })
  }
}

<template>
  <select v-if="branches" class="custom-select header-location__select" @change="setBranch">
    <option v-for="(item, index) in branches" :key="index" :value="item.id">{{ item.name }}</option>
  </select>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup () {
    const store = useStore()
    const branches = computed(() => {
      return store.getters['branch/branches']
    })
    function setBranch (e) {
      const id = Number.parseInt(e.target.value)
      if (id) {
        store.commit('branch/SET_BRANCH_ID', id)
      }
    }
    return {
      branches,
      setBranch
    }
  }
}
</script>

<template>
  <footer class="footer mt-5">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-md-12 col-sm-4 order-tablet-1 text-center footer__item">
          <img src="@/assets/img/logo-big.svg" alt="" class="img-fluid footer__logo" >
          <div class="text-center">
            {{ t("hozland_store") }}, {{ new Date().getFullYear() }}
          </div>
        </div>
        <div class="col-xl-5 col-md-6 footer__item">
          <div class="footer-columns">
            <div class="footer-columns__item">
              <div class="footer__title title title--third">{{ t("about_company") }}</div>
              <nav class="footer-links">
                <a href="" class="footer-links__link">Контакты</a>
                <a href="" class="footer-links__link">О нас</a>
                <a href="" class="footer-links__link">FAQ</a>
                <a href="" class="footer-links__link">Бренды</a>
                <a href="" class="footer-links__link">Новости</a>
                <a href="" class="footer-links__link">Сотрудничество</a>
              </nav>
            </div>
            <div class="footer-columns__item">
              <div class="footer__title title title--third">{{ t("services") }}</div>
              <nav class="footer-links">
                <a href="" class="footer-links__link">Доставка и оплата</a>
                <a href="" class="footer-links__link">Гарантии</a>
                <a href="" class="footer-links__link">Рассрочка</a>
              </nav>
            </div>
            <div class="footer-columns__item">
              <div class="footer__title title title--third">{{ t("sections") }}</div>
              <nav class="footer-links">
                <a href="" class="footer-links__link">Акции</a>
                <a href="" class="footer-links__link">Сезонные</a>
                <a href="" class="footer-links__link">Топ продаж</a>
                <a href="" class="footer-links__link">Новинки</a>
              </nav>
            </div>
          </div>
        </div>
        <div v-if="contactPage" class="col-xl-2 col-md-3 col-sm-4 col-6 footer__item">
          <div class="footer__title title title--third">{{ t('contacts') }}</div>
          <p>{{ contactPage.time_work.time }}</p>
          <a v-for="(item, index) in contactPage.phones" :href="'tel:' + item.replace(/[^0-9]/g, '')" :key="index" class="footer__contact-link mt-1 mb-1">{{ item }}</a>
          <p>{{ t('email') }}</p>
          <a v-for="(item, index) in contactPage.email" :href="'mailto:' + item" :key="index" class="footer__contact-link mt-1 mb-1">{{ item }}</a>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-4 col-6 footer__item">
          <div class="footer__title title title--third">{{ t('social_networks')}}</div>
          <div class="social-media">
            <a :href="t('instagram_url')" class="social-media__link">
              <img src="@/assets/img/icons/insta.svg" alt="" class="social-media__icon icon-svg">
            </a>
            <a :href="t('telegram_url')" class="social-media__link">
              <img src="@/assets/img/icons/telegram.svg" alt="" class="social-media__icon icon-svg">
            </a>
            <a :href="t('youtube_url')" class="social-media__link">
              <img src="@/assets/img/icons/youtube.svg" alt="" class="social-media__icon icon-svg">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="btn btn--scroll-to" @click="scrollTo">
    <svg width="15" height="9" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#arrow-down"></use></svg>
  </div>
</template>
<script>
// import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
export default {
  setup () {
    const { t } = useI18n()
    const store = useStore()
    onMounted(() => {
      store.dispatch('page/fetchPages')
    })
    const contactPage = computed(() => {
      const pages = store.getters['page/pages']
      if (pages.length > 0) {
        const page = pages.find(p => p.type === 'contact')
        return page.content
      }
      return null
    })
    function scrollTo () {
      document.getElementById('app').scrollIntoView()
    }
    return {
      t,
      contactPage,
      scrollTo
    }
  }
}
</script>

<template>
  <div v-if="locales" id="dropdown-language" class="dropdown b-dropdown header__language header-dropdown btn-group">
    <button id="dropdown_language_toggle" data-bs-toggle="dropdown" aria-expanded="false" type="button" class="btn dropdown-toggle btn-link">{{ locale.name }}</button>
    <ul role="menu" tabindex="-1" aria-labelledby="dropdown_language_toggle" class="dropdown-menu header__language-menu">
      <li v-for="(item, index) in locales" :key="index" role="presentation">
        <a role="menuitem" target="_self" class="dropdown-item header__language-link" @click="setLocale(item.code)">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const locales = computed(() => {
      return store.getters['lang/locales']
    })
    const locale = computed(() => {
      const l = store.getters['lang/locales'].find(c => c.code === store.getters['lang/locale'])
      return l
    })

    function setLocale (locale) {
      store.dispatch('lang/setApiLocale', { locale: locale })
    }
    return {
      locales,
      locale,
      setLocale
    }
  }
}
</script>

<template>
  <div v-if="phones" class="dropdown b-dropdown header-dropdown btn-group">
    <button class="btn dropdown-toggle" type="button" id="dropdownPhones" data-bs-toggle="dropdown" aria-expanded="false">
      {{ phones[0].phone }}
    </button>
    <ul v-if="phones" class="dropdown-menu header-phone__list" aria-labelledby="dropdownPhones">
      <li v-for="(item, index) in phones" role="presentation" :key="index"><a class="dropdown-item header-phone__link" role="menuitem">{{ item.phone }}</a></li>
    </ul>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup () {
    const store = useStore()
    const phones = computed(() => {
      const b = store.getters['branch/currentBranch']
      return b.phones
    })
    return {
      phones
    }
  }
}
</script>

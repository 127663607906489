// Middlewares import
import auth from '~/middleware/auth'
import checkAuth from '~/middleware/check-auth'
import guest from '~/middleware/guest'
// lazy load components
function lazyLoad (path) {
  return () => import('~/pages/' + path).then(m => m.default || m)
}
export default [
  // Account
  { path: '/', name: 'account', component: lazyLoad('account/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Auth
  { path: '/login', name: 'login', component: lazyLoad('auth/login'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/register', name: 'register', component: lazyLoad('auth/register'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/password/reset', name: 'password.reset', component: lazyLoad('auth/password/email'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/password/reset/:token', name: 'password.reset.token', component: lazyLoad('auth/password/reset'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/email/verify/:id', name: 'verification.verify', component: lazyLoad('auth/verification/verify'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/email/resend', name: 'verification.resend', component: lazyLoad('auth/verification/resend'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  // Order
  { path: '/orders', name: 'orders', component: lazyLoad('order/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Settlement
  { path: '/settlement', name: 'settlement', component: lazyLoad('settlement/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Refunds
  { path: '/refunds', name: 'refunds', component: lazyLoad('refund/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Help
  { path: '/help', name: 'help', component: lazyLoad('help/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Wishlist
  { path: '/wishlist', name: 'wishlist', component: lazyLoad('wishlist/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Viewed
  { path: '/viewed', name: 'viewed', component: lazyLoad('viewed/index'), meta: { middlewares: [checkAuth, auth], layout: 'account' } },
  // Errors
  { path: '/notFound', name: 'notFound', component: lazyLoad('errors/notFound'), meta: { middlewares: [checkAuth, guest], layout: 'account' } },
  { path: '/:catchAll(.*)', redirect: 'notFound' }
]

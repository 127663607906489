<template>
  <div id="_layout">
    <div class="__hozland-layout">
      <Header />
      <div class="container">
        <child />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Header from './partials/header'
import Footer from './partials/footer'
export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer
  }
}
</script>

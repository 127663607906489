import * as types from '../mutation-types'
// import axios from 'axios'
// state
export const state = {
  menu: [],
  showMenu: false
}
// getters
export const getters = {
  menu: state => state.menu,
  showMenu: state => state.showMenu
}
// mutations
export const mutations = {
  [types.SET_MENU] (state, menu) {
    state.menu = menu
  },
  [types.SHOW_MENU] (state, status) {
    state.showMenu = status
  }
}
// actions
export const actions = {}

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { nextTick } from 'vue'
import middlewarePipeline from './middlewarePipeline'
/*
const globalMiddleware = ['check-auth']
const routeMiddleware = resolveMiddleware(
  require.context('~/middleware', false, /.*\.js$/)
)
*/
const router = createRoutes()
export default router
function createRoutes () {
  const router = createRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    history: createWebHistory(),
    routes
  })
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)

  return router
}
async function beforeEach (to, from, next) {
  if (!to.meta.middlewares) return next()
  const middlewares = to.meta.middlewares
  const context = { to, from, next }
  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1)
  })
  /*
 let components = []
  try {
    const c = to.matched.map(record => Object.values(record.components)).flat()
    components = await resolveComponents(c)
  } catch (error) {
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload(true)
      return
    }
  }
  if (components.length === 0) {
    return next()
  }
  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components)
  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
  */
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
/*
function callMiddleware (middleware, to, from, next) {
  const stack = middleware.reverse()
  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }
    const { middleware, params } = parseMiddleware(stack.pop())
    if (typeof middleware === 'function') {
      middleware(to, from, _next, params)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next, params)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }
  _next()
}
*/
async function afterEach (to, from, next) {
  await nextTick()
}
/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
/*
function resolveComponents (components) {
  return Promise.all(components.map(component => (typeof component === 'function' ? component() : component)))
}
*/
/**
 * @param  {String|Function} middleware
 * @return {Object}
 */
/*
function parseMiddleware (middleware) {
  if (typeof middleware === 'function') {
    return { middleware }
  }
  const [name, params] = middleware.split(':')
  return { middleware: name, params }
}
*/
/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
/*
function getMiddleware (components) {
  const middleware = [...globalMiddleware]
  components.filter(c => c.middleware).forEach(component => {
    if (Array.isArray(component.middleware)) {
      middleware.push(...component.middleware)
    } else {
      middleware.push(component.middleware)
    }
  })
  return middleware
}
*/
/**
 * @param  {Object} requireContext
 * @return {Object}
 */
/*
function resolveMiddleware (requireContext) {
  return requireContext.keys()
    .map(file =>
      [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((guards, [name, guard]) => (
      { ...guards, [name]: guard.default }
    ), {})
}
*/

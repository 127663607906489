<template>
  <form class="header__search header__indent" :class="{ 'show': showSearch }">
    <span role="button" type="button" class="btn-reset btn-close-search" @click="showSearch = !showSearch"><svg class="icon-svg" width="20" height="20"><use xlink:href="@/assets/img/sprite.svg#carousel-left"></use></svg></span>
    <input class="header__search-input form-control" type="text" placeholder="Я ищу..">
    <button type="submit" class="header__search-submit btn-reset"><svg class="icon-svg" width="20" height="20"><use xlink:href="@/assets/img/sprite.svg#search"></use></svg></button>
  </form>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup () {
    const showSearch = ref(false)
    return {
      showSearch
    }
  }
}
</script>

<template>
    <nav v-if="isTablet" class="nav-side" :class="{ 'open-side': show }">
      <div class="nav-side__header">
        <img src="@/assets/img/logo.svg" alt="" class="nav-side__logo">
        <a href="" class="nav-side__user nav-side__user--login">
          <svg width="24" height="24" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#user"></use></svg>
          <div class="nav-side__user-info">
            <span>Здравствуйте,</span>
            <span class="nav-side__user-name">Игорь</span>
          </div>
        </a>
        <a href="" class="nav-side__user nav-side__user--no-login d-none">
          <svg width="24" height="24" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#enter"></use></svg>
          <div class="nav-side__user-info">
           Войти  <br> в аккаунт
          </div>
        </a>
        <button class="btn-reset nav-side__close" @click="openNav = !openNav">
          <svg width="19" height="18" class="icon-svg"><use xlink:href="@/assets/img/sprite.svg#close"></use></svg>
        </button>
      </div>
      <div class="nav-side__body">
        <ul v-if="navigation.length > 0" class="nav-side__list">
          <li v-for="(nav, index) in navigation" :key="index">
            <a :href="nav.url" class="nav-side__link">{{ nav.name }}</a>
          </li>
        </ul>
      </div>
      <div class="nav-side__footer">
        <div class="header-location nav-side__location">
          <svg class="icon-svg" width="14" height="14"><use xlink:href="@/assets/img/sprite.svg#cursor"></use></svg>
          <Branch />
        </div>
        <div class="header-feedback nav-side__feedback">
          <div class="header-phone">
            <Phones />
          </div>
          <button class="header-feedback__btn btn-reset link" @click="openNav = !openNav">Заказать звонок</button>
        </div>
        <ul v-if="locales" class="nav-side__language">
          <li v-for="(item, index) in locales" :key="index">
            <a class="nav-side__language-link" @click="setLocale(item.code)" :class="{ 'nav-side__language-link--active': locale === item.code }">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </nav>
</template>
<script>
import { ref } from '@vue/runtime-core'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Branch from './branch'
import Phones from './phones'
export default {
  components: {
    Phones,
    Branch
  },
  setup () {
    const store = useStore()
    const width = ref(window.innerWidth)
    const showSearch = ref(false)
    const search = ref('')
    onMounted(() => {
      window.onresize = () => {
        width.value = window.innerWidth
      }
      window.addEventListener('resize', isTabletCheck, false)
      window.addEventListener('load', isTabletCheck, false)

      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 43) {
          document.querySelector('.header').classList.add('header--sticky')
        } else {
          document.querySelector('.header').classList.remove('header--sticky')
        }
      })
      window.addEventListener('load', () => {
        if (window.pageYOffset > 43) {
          document.querySelector('.header').classList.add('header--sticky')
        } else {
          document.querySelector('.header').classList.remove('header--sticky')
        }
      })
      if (store.getters['branch/branches'].length === 0) {
        store.dispatch('branch/getBranches')
      }
    })
    const navigation = computed(() => {
      return store.getters['menu/menu']
    })
    const isTablet = computed(() => {
      if (width.value <= 992) {
        return true
      }
      return false
    })
    const locales = computed(() => {
      return store.getters['lang/locales']
    })
    const locale = computed(() => {
      return store.getters['lang/locale']
    })
    const show = computed(() => {
      return store.getters['menu/showMenu']
    })
    // methods
    function openNav () {
      const s = !store.getters['menu/showMenu']
      store.commit('menu/SHOW_MENU', s)
    }
    function isTabletCheck () {
      if (width.value <= 992) {
        return true
      }
      return false
    }
    function setLocale (locale) {
      store.dispatch('lang/setApiLocale', { locale: locale })
    }
    return {
      navigation,
      show,
      isTablet,
      showSearch,
      search,
      locales,
      locale,
      openNav,
      setLocale
    }
  }
}
</script>

// branch.js
export const SET_BRANCHES = 'SET_BRANCHES'
export const SET_BRANCH_ID = 'SET_BRANCH_ID'
export const SET_BRANCH_PHONES = 'SET_BRANCH_PHONES'
// menu.js
export const SET_MENU = 'SET_MENU'
export const SHOW_MENU = 'SHOW_MENU'
// lang.js
export const SET_LOCALE = 'SET_LOCALE'
// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
// page.js
export const SET_PAGES = 'SET_PAGES'
